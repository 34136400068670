<template>
  <v-dialog v-model="dialog" persistent max-width="420">
    <v-card>
      <v-card-title v-if="title" class="delete-title delete-center">
        <span class="delete-bold"> Удалить {{ title }}?</span>
      </v-card-title>
      <v-card-text
        v-if="isDescription"
        class="delete-center d-flex flex-column"
        :class="{ 'pt-8': !title }"
        style="white-space: pre-wrap;"
      >
        <span class="text-align_left">{{ description }}</span>
        <div class="d-flex align-baseline mt-4 text-align_left">
          <v-checkbox
            style="position: absolute"
            v-model="agreement"
            @click="agreement != agreement"
          ></v-checkbox>
          <span class="mt-2">
            <span class="ml-7">Заблокировать доступ </span>
          </span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          width="154"
          height="42"
          style=""
          class="ma-5 btn btn_color_white"
          text
          @click="close"
        >
          Отмена
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!agreement"
          width="154"
          height="42"
          color="#EB5C6D"
          class="ma-5 btn btn_color_red"
          style="color:white"
          @click="realDelete"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeletePopupAdmin",
  data() {
    return {
      agreement: false
    };
  },
  props: {
    name: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    deleteFunction: {
      type: Function
    },
    dialog: {
      type: Boolean
    },
    description: {
      type: String
    }
  },
  computed: {
    isDescription() {
      return this.description ? true : false;
    }
  },
  methods: {
    close() {
      (this.dialog = false),
        (this.agreement = false),
        this.$emit("closeDeletePopup", false);
    },
    realDelete() {
      this.deleteFunction();
      this.close();
    }
  }
};
</script>

<style scoped>
.delete-title {
  padding-top: 30px !important;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px !important;
  /* or 143% */

  text-align: center !important;

  color: #515a6e;
}
.delete-bold {
  font-weight: 700 !important;
}

.delete-center {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
