import phoneFilter from "@/helpers/filters/phoneFilter";

const tableColsPersonnel = [
  {
    text: "Телефон",
    renderFunction: value => {
      return phoneFilter.execute(value?.phone_number);
    },
    sort: true
  },
  {
    text: "Роль",
    value: "position",
    renderFunction: value =>
      `<div style="color: ${value.position == 30 ? "#95C23D" : " #000"}">${
        value.position == 30
          ? "Администратор"
          : value.position == 20
          ? "Воспитатель"
          : "Другой персонал"
      }</div>`,
    sort: true
  },
  {
    text: "Пользователь",
    renderFunction: value => {
      return value?.additional_fields.length != 0
        ? value?.additional_fields[0].value
        : "-";
    }
  }

  // {
  //   text: "№ группы",
  //   renderFunction: value =>
  //     value?.children_groups
  //       ? value.children_groups.filter(elem => elem != "").join(", ")
  //       : "-"
  // }
];

export default tableColsPersonnel;
